import { Factory, Model } from 'miragejs';
import { returns } from '../mocks/returns';

const models = () => {
  return {
    shipping: Model,
  };
};

const factories = () => {
  return {
    shipping: Factory.extend({
      'id': 4,
      'return_key': '38e845b4-b66f-48b8-a754-39a519ebe6cb',
      'needs_label': false,
      'label': null,
      'prepaid_labels': [],
      'return_address': {
        'id': 4,
        'hash': null,
        'created_at': '2020-07-16 13:32:40',
        'updated_at': '2020-07-16 13:32:40',
        'name': 'Loop Warehouse',
        'company': null,
        'address1': '255 Warehouse Avenue',
        'address2': '',
        'city': 'Columbus',
        'state': 'Ohio',
        'zip': '43034',
        'country': 'United States',
        'country_code': 'US',
        'phone': null
      },
      'tracker': null
    })
  };
};

const seed = (context) => {
  returns.forEach((item) => {
    const {
      id,
      return_key,
      needs_label,
      label,
      prepaid_labels,
      return_address
    } = item;
    context.create('shipping', {
      id,
      return_key,
      needs_label,
      label,
      prepaid_labels,
      return_address
    });
  });
};

const routes = (context) => {
  context.get('/:shopId/return/:returnKey/shipping', (schema, request) => {
    const return_key = request.params.returnKey;
    return findOrFail(schema, return_key);
  });
};

const findOrFail = (schema, returnKey) => {
  const item = schema.shippings.findBy({ return_key: returnKey });
  if (item) {
    return item.attrs;
  }

  return new Response(
    404,
    { 'Content-Type': 'application/json' },
    {
      errors: [{
        title: 'return not found',
        description: 'return could not be found'
      }]
    }
  );
};

export default {
  factories,
  routes,
  models,
  seed
};
