import { ref } from 'vue';
import store from '@/store';
import router from '@/router';
import bootTheme from '@/js/mixins/theme';
import { setUpGA } from '@/js/googleAnalytics';
import { inIframe } from '@/js/messages';
import { initSegment } from '@/js/segment';
import useStorageMethods from '@/js/mixins/store';
const {
  getStore,
} = useStorageMethods();

const generateTitle = (storeName, titleSetting) => {
  if (titleSetting !== 'Returns') {
    return titleSetting;
  }
  if (storeName) {
    return `${storeName.charAt(0).toUpperCase()}${storeName.slice(1)} Returns`;
  }

  return titleSetting;
};

const setMetaDescription = (description) => {
  document.querySelector('meta[name="description"]')
    .setAttribute('content', description);
};

const checkActive = (shop) => {
  return shop.state === 'uninstalled' || shop.state === 'inactive' || !shop.portal_active;
};

/**
 * @param {Object} route - A vue router route object
 * @param {Object} data - The init data, sent from the api/pulled from localstorage
 */

//@TODO: Remove Settimeout and figure out what's going on here with routes not having data
const initData = (routeObj, data) => {
  const bypassRouteNames = [
    'lookup',
    'giftReceipt',
    'gift',
    'customerLookup',
    'customerHistory',
    'StatusPage',
    'link',
    'cart',
    'gift-link',
    'tracking-view',
    'tracking-error',
    'tracking-lookup'
  ];

  // Save all init data to vuex
  Object.entries(data).forEach(([key, value]) => {
    store.commit('updateData', {
      name: key,
      data: value
    });
  });

  const route = ref(routeObj);

  const shop = store.getters.shop;
  const theme = store.getters.theme;
  const content = store.getters.content;

  document.title = generateTitle(shop.name, content.global.title);
  setMetaDescription(content.global.metaDescription);

  //Detect and set currency
  if (data.currency !== 'USD') {
    store.commit('updateData', {
      name: 'currency',
      data: data.currency
    });
  }

  bootTheme(theme, route);
  initSegment();

  if (content.moduleAnalytics) {
    setUpGA(content.moduleAnalytics.ga);
  } else {
    setUpGA(null);
  }

  //Make sure client is active
  if (checkActive(shop)) {
    return false;
  }

  const savedOrder = getStore('order');
  const confirmData = getStore('confirmData');
  const token = getStore('token');
  const uuid = getStore('uuid');
  const cartToken = getStore('cartToken');
  const source = getStore('source');
  const analytics = getStore('analytics');
  const singleItemEligible = getStore('singleItemEligible');


  //Grab Local Storage items if they exist, react accordingly
  // eslint-disable-next-line no-prototype-builtins
  if (!store.getters.order?.hasOwnProperty('id') && savedOrder !== undefined) {
    store.commit('updateOrder', savedOrder);
    store.commit('setOriginalLineItems', savedOrder?.line_items);
  }

  store.dispatch('return/hydrate');
  store.dispatch('totals/hydrate');
  store.dispatch('products/hydrate');
  store.dispatch('currencies/hydrate');

  const isOnAllowedRoute = ['credit', 'review', 'shop'].some((item) => {
    return window.location.hash.startsWith(`#/${item}`);
  });
  if (typeof savedOrder === 'object' && isOnAllowedRoute) {
    store.dispatch('fees/getFees', savedOrder);
  }

  if (route.value.name && !savedOrder && !bypassRouteNames.includes(route.value.name)) {
    router.push({ name: 'lookup' });
  }

  if (confirmData !== null) {
    store.commit('updateData', {
      name: 'confirmData',
      data: confirmData
    });
  }

  if (token) {
    store.commit('updateData', {
      name: 'token',
      data: token
    });
  }

  if (uuid) {
    store.commit('updateData', {
      name: 'uuid',
      data: uuid
    });
  }

  //Check if we have an onstore cart token
  if (cartToken) {
    store.commit('updateData', {
      name: 'cartToken',
      data: cartToken,
      save: true
    });
  }

  if (source) {
    store.commit('updateData', {
      name: 'source',
      data: source,
      save: true
    });
  }

  if (analytics) {
    store.commit('analytics/setData', analytics);
  }

  if (singleItemEligible !== undefined) {
    store.commit('updateData', {
      name: 'singleItemEligible',
      data: singleItemEligible,
      save: true
    });
  }

  // Grab return reasons if we haven't already
  if (!store.state.reasons.reasons.length) {
    store.dispatch('reasons/get');
  }

  // Set language first based on localStorage, if present
  // Otherwise, automatically set language based on the browser language
  const localLang = localStorage.getItem('lang');
  const browserLanguage = navigator.language.slice(0, 2).toLowerCase();

  if (localLang && store.state.supportedLanguages.includes(localLang)) {
    store.dispatch('setLanguage', localLang);
  } else if (store.state.supportedLanguages.includes(browserLanguage)) {
    store.dispatch('setLanguage', browserLanguage);
  }

  // Allow override of set language based on a lang= query string
  if (route.value.query.lang) {
    if (store.state.supportedLanguages.includes(route.value.query.lang)) {
      store.dispatch('setLanguage', route.value.query.lang);
    }
    router.replace({
      ...router.currentRoute,
      query: null
    });
  }

  // Set POS and customizer flags
  if (window.location.href.includes('pos=true')) {
    store.commit('setIsPOS', true);
  }

  if (inIframe() && route.value.query.customizer) {
    store.commit('setEdits', {
      type: 'active',
      data: true
    });
  }

  if (inIframe() && route.value.query.embed && route.value.query.cartToken && savedOrder.enabled.on_store_api === 'yes') {
    router.push({
      name: 'cartv2',
      params: {
        token: route.value.query.cartToken,
        to: route.value.query?.to ?? null,
      }
    });
  }
};

export default initData;
