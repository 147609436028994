<template>
  <div class="print-label">
    <div
      class="print-label__label-buttons"
      data-testid="print_label_buttons"
    >
      <base-button
        :class="computedClass"
        data-testid="print_label_button"
        download="return_label.pdf"
        target="_blank"
        rel="noopener noreferrer"
        icon="printer"
        :to="url"
        :activated="generate"
        @click="print"
      >
        <render-content>
          {{ text }}
        </render-content>
      </base-button>
      <slot
        v-if="!isEvriLabel"
        name="packingSlip"
        classes="print-label__secondary-button"
      />
      <base-button
        v-if="isEvriLabel"
        class="print-label__secondary-button"
        target="_blank"
        type="secondary"
        icon="location"
        to="https://www.evri.com/find-a-parcelshop"
        data-testid="print_label_secondary_button"
      >
        {{ $content.moduleEvriReturnCode.cta }}
      </base-button>
    </div>

    <div class="print-label__links">
      <base-button
        v-if="showToggle"
        class="print-label__links-qr"
        data-testid="print_label_links-qr"
        type="link"
        @click="toggle"
      >
        {{ $content.moduleLabel.toggle }}
      </base-button>
      <base-button
        v-if="trackingUrl"
        v-track="'track return clicked'"
        class="print-label__links-tracking"
        target="_blank"
        type="link"
        :to="trackingUrl"
      >
        {{ $content.moduleTrackingInformation.trackingLinkText }}
      </base-button>
    </div>
  </div>
</template>

<script>
import {
  BaseButton,
} from '@loophq/design-system';
import { carriers } from '@/js/constants/carriers';
import Returns from '@/js/controllers/return';
import { views } from './actionMaps';
import { shippingProviders } from '@/js/constants/shipping';

export default {
  components: {
    BaseButton,
  },
  props: {
    label: {
      type: Object,
      required: false
    },
    generate: {
      type: Boolean,
      required: false,
      default: false
    },
    returnKey: {
      type: String,
      required: true
    },
    trackingUrl: {
      type: String,
      required: false,
    },
    packingSlipsEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    hasCombinedLabelsWithPackingSlipsFlag: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  emits: ['toggleView'],
  data() {
    return {
      secondaryButtonCopy: '',
      secondaryButtonUrl: '',
      secondaryButtonIcon: '',
    };
  },
  computed: {
    url() {
      return (this.packingSlipsEnabled && this.hasCombinedLabelsWithPackingSlipsFlag)
        ? this.label?.labelPackingSlip
        : this.label?.labelUrl ?? null;
    },
    text() {
      const content = (this.packingSlipsEnabled && this.hasCombinedLabelsWithPackingSlipsFlag)
        ? this.$content.moduleLabelWithPackingSlip
        : this.$content.moduleLabel;

      return this.generate ? content.generating : content.cta;
    },
    labelWasCreatedToday() {
      const labelDate = (new Date(this.label?.createdAt ? `${this.label.createdAt}Z` : null)).setUTCHours(0, 0, 0, 0);
      const today = (new Date()).setUTCHours(0, 0, 0, 0);
      return labelDate === today;
    },
    showToggle() {
      // we can remove this when we add QR code support for Easyship SBLWW
      if (this.url?.includes('easyship')) {
        return false;
      }

      // If the carrier is USPS, the toggle is able to generate a QR code,
      // so even if a QR code hasn't been created yet, the toggle should still be shown.
      const isUsps = this.label?.carrier === carriers.USPS || this.label?.carrier === carriers.USPSRETURNS;
      const notUsingExternalConnector = this.label?.shippingProviderId !== shippingProviders.EXTERNAL_CONNECTOR;

      return this.label?.qrCodeUrl ||
        (
          this.labelWasCreatedToday &&
          this.uspsQrSettingIsOn &&
          isUsps &&
          notUsingExternalConnector
        );
    },
    uspsQrSettingIsOn() {
      return this.$store.getters.settings.generateUspsQrCodes;
    },
    isEvriLabel() {
      return this.label?.carrier === carriers.EVRI;
    },
    computedClass() {
      return (this.packingSlipsEnabled && this.hasCombinedLabelsWithPackingSlipsFlag)
        ? 'print-label__print-button-full'
        : 'print-label__print-button';
    }
  },
  methods: {
    print() {
      Returns.updatePrintLabelCount(this.returnKey);

      this.$trackEvent('print label clicked');

      if (this.packingSlipsEnabled && this.hasCombinedLabelsWithPackingSlipsFlag) {
        this.$trackEvent('generate packing slip clicked');
      }
    },
    toggle() {
      this.$emit('toggleView', views.QR_CODE);
    }
  }
};
</script>

<style lang="scss" scoped>
$block: '.print-label';

#{$block} {
  display: flex;
  flex-direction: column;

  &__label-buttons {
    display: flex;
  }

  &__print-button {
    width: 50%;
    margin-right: var(--spacing-400);

    :deep(.base-button__text) {
      width: auto;
    }
  }

  &__print-button-full {
    width: 100%;

    :deep(.base-button__text) {
      width: auto;
    }
  }

  &__secondary-button,
  :slotted(.print-label__secondary-button) {
    width: 50%;

    :deep(.base-button__text) {
      width: auto;
    }
  }

  &__links {
    display: flex;
    margin-top: var(--spacing-300);

    &-qr {
      align-self: flex-start;
      width: auto;
      margin-right: var(--spacing-400);
    }

    &-tracking {
      align-self: flex-start;
      width: auto;
    }
  }

  @media screen and (max-width: $break-small) {
    &__print-button {
      width: 100%;
      margin-bottom: var(--spacing-300);
    }

    &__label-buttons {
      flex-direction: column;
    }

    &__secondary-button {
      width: 100%;
    }

    &__links {
      flex-direction: column;

      &-qr {
        margin-bottom: var(--spacing-200);
      }
    }
  }
}
</style>
