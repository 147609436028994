export const ruleTypes = {
  CONDITION: 'Condition',
  USER_INPUT: 'UserInput',
  RETURN_OUTCOME: 'ReturnOutcome',
  EXCLUDE_OUTCOME: 'ExcludeOutcome',
  KEEP_DONATE: 'KeepDonate',
  REVIEW_REJECT: 'ReviewReject',
  LINE_ITEM_FEES: 'LineItemFees',
  REASON_GROUP_OVERRIDE: 'ReasonGroupOverride',
  BONUS_CREDIT: 'BonusCredit',
};

export const outcomes = {
  KEEP_ITEM: 'keep',
  REJECT: 'reject',
  REVIEW: 'review',
  DONATE: 'donate',
};

export const triggers = {
  ITEM_SELECTED: 'item-selected',
  REASON_SELECTED: 'reason-selected',
  RETURN_COMPLETED: 'return-completed',
};
