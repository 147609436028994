import {
  applyOutcome,
  excludeOutcome,
  revertExcludeOutcomes,
  revertReturnOutcomes,
  setOutcomeSetByWorkflow,
  applyPhotoUploadRequirements,
  applyPreCalculatedLineItemFees,
  applyBonusCredit,
  revertBonusCredit
} from '@/js/helpers/workflows';
import { getWorkflowResults, mapWorkflowsPayload } from '@/js/controllers/workflows';
import { cloneDeep, uniqBy } from 'lodash';
import { ruleTypes } from '@/js/constants/workflows';
import { logError } from '@/js/helpers/errors';

export default {
  namespaced: true,
  actions: {
    async evaluate({ rootState, rootGetters }, data) {
      try {
        const orderState = cloneDeep(rootState.order);
        const { lineItem, order, trigger } = data;
        const workflowInputs = mapWorkflowsPayload(lineItem, order, rootState.return, trigger);
        const results = await getWorkflowResults(rootGetters.shop.id, workflowInputs);

        const uniqueResults = uniqBy(results, 'type').filter(n => n);

        uniqueResults.forEach(async workflow => {
          setOutcomeSetByWorkflow(lineItem, workflow, orderState);
          if (workflow.type === ruleTypes.USER_INPUT && workflow.value?.type === 'upload') {
            await applyPhotoUploadRequirements(lineItem, workflow);
          }
          if (workflow.type === ruleTypes.KEEP_DONATE || workflow.type === ruleTypes.REVIEW_REJECT) {
            await applyOutcome(workflow, lineItem, orderState, rootGetters.shop.id);
          }
          if (workflow.type === ruleTypes.EXCLUDE_OUTCOME && !orderState.allowlisted) {
            await excludeOutcome(workflow, lineItem);
          }

          if (workflow.type === ruleTypes.LINE_ITEM_FEES || workflow.lineItemFees) {
            const activeFeePercentage = workflow.type === ruleTypes.LINE_ITEM_FEES ? workflow.value.lineItemFees : null;
            let activeFee = workflow.lineItemFees ? workflow.lineItemFees[0] : rootState.order.line_items[lineItem.id].fallbackLineItemFees;
            if (activeFeePercentage) {
              activeFee = rootState.order.line_items[lineItem.id].applicableLineItemFees?.find(({ creditPercentage }) => creditPercentage === activeFeePercentage);
            }
            applyPreCalculatedLineItemFees(lineItem, workflow.lineItemFees, activeFee);
          }
          if (workflow.type === ruleTypes.BONUS_CREDIT) {
            applyBonusCredit(workflow, orderState);
          }
        });

        const excludedOutcomes = uniqueResults?.find(rule => rule.type === ruleTypes.EXCLUDE_OUTCOME);
        const returnOutcomes = uniqueResults?.find(rule => rule.type === ruleTypes.KEEP_DONATE || rule.type === ruleTypes.REVIEW_REJECT);
        const bonusCredit = uniqueResults?.find(rule => rule.type === ruleTypes.BONUS_CREDIT);
        const originalLineItem = rootState?.originalLineItems[lineItem.id];
        if (!excludedOutcomes && lineItem.outcomesSetByWorkflow.find(item => item.name === ruleTypes.EXCLUDE_OUTCOME)) {
          revertExcludeOutcomes(lineItem, originalLineItem);
        }
        if (!returnOutcomes && lineItem.outcomesSetByWorkflow.find(item => item.name === ruleTypes.KEEP_DONATE || ruleTypes.REVIEW_REJECT)) {
          revertReturnOutcomes(lineItem);
        }
        if (!bonusCredit) {
          revertBonusCredit(orderState);
        }
        return uniqueResults;
      } catch (error) {
        logError(error);
      }
    },
  },
};
