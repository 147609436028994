import { computed } from 'vue';
import { useStore } from 'vuex';
import Order from '@/js/controllers/order';
import { replacePlaceholderUsingObject } from '@/js/utility/replacePlaceholderUsingObject';
import { cloneDeep } from 'lodash';
import { track } from '@/js/segment';
import { customerPortalEvents } from '@/js/constants/segment';
import { orderLineItemStatuses } from '@/js/constants/orderLineItemStatuses';

export default function useLineItems(settings) {
  const store = useStore();

  const order = computed(() => {
    return store.getters.order;
  });

  const lineItems = computed(() => {
    return order.value?.line_item_ids.map((id) => {
      return order.value.line_items?.[id] ?? { id, loading: true };
    });
  });

  const bundleItems = computed(() => {
    return lineItems.value?.reduce((acc, cur) => {
      // If this item is not in a bundle, bail out
      if (!cur.bundle_oli_id) {
        return acc;
      }

      // Get the current items in this bundle if they exist
      const existing = acc[cur.bundle_oli_id] ?? [];

      // Add this item to it's bundle
      return {
        ...acc,
        [cur.bundle_oli_id]: [...existing, cur],
      };
    }, {});
  });

  // Keeps track of all selected line items, and counts them
  const selectedItems = computed(() => {
    let parent = {};
    let sItems = {};
    let counter = 0;

    Object.entries(order.value?.line_items ?? {}).forEach(([ID, item]) => {
      if (item.returnType != undefined) {
        sItems[ID] = {
          returnType: item.returnType,
          storefront: item.storefront ?? false
        };
        counter++;
      }
    });

    parent['count'] = counter;
    parent['items'] = sItems;
    return parent;
  });

  const availableDestination = computed(() => {
    const item = nonBundleLineItems.value.find((item) => {
      return validationCheck(item) && item.returnType && item.destinations?.length;
    });

    return item?.destinations.map((item) => item.id) ?? [];
  });

  const nonBundleLineItems = computed(() => {
    return lineItems.value?.filter((item) => !item.bundle_oli_id);
  });

  const eligibleItems = computed(() => {
    const tempEligibleItems = nonBundleLineItems.value?.filter((item) =>
      validationCheck(item)
    );

    const tempSelectedItems = tempEligibleItems?.filter((item) => item.returnType);
    const hasSelectedWarrantyEligibleItems = tempSelectedItems.some((item) => (item?.qualifiedWarranties || []).length);

    return tempEligibleItems.map((item) => {
      const allDisabled = settings.singleItem && selectedItems.value.count > 0;

      // Only consider product rules if the feature flag is enabled
      const destinations = item.destinations || [];
      const isValidDestination = destinations.some((destination) =>
        availableDestination.value?.includes(destination.id)
      );
      const destinationIsDisabled =
        settings.enforceProductRules &&
        tempSelectedItems.length &&
        availableDestination.value.length &&
        !isValidDestination;

      let doesNotQualifyForCurrentWarranty = false;
      if (
        // if has items selected for warranty return
        store.getters.settings.warrantiesEnabled
        && hasSelectedWarrantyEligibleItems
        && store.state.return.workflowGroupId
        && item?.qualifiedWarranties
      ) {
        doesNotQualifyForCurrentWarranty = !item?.qualifiedWarranties.includes(store.state.return.workflowGroupId);
      } else if (
        // if has items selected for standard return
        store.getters.settings.warrantiesEnabled
        && tempSelectedItems.length
        && !hasSelectedWarrantyEligibleItems
        && item?.qualifiedWarranties?.length
      ) {
        store.commit('return/setWorkflowGroupId', null);
      }

      return {
        ...item,
        disabled: !!(allDisabled || destinationIsDisabled) || doesNotQualifyForCurrentWarranty,
      };
    });
  });

  const ineligibleLineItems = computed(() => {
    return nonBundleLineItems.value?.filter((item) => !validationCheck(item) && !isReturnCoverageProduct(item));
  });

  const resellLineItems = computed(() => {
    return ineligibleLineItems.value?.filter((item) => item.returned_at === null && !item.excluded.resell);
  });

  const warrantyAllowedOutcomes = computed(() => {
    const warrantyItem = Object.values(order.value?.line_items).find(
      (item) => item.warrantyAllowedOutcomes !== undefined
    );

    return warrantyItem ? warrantyItem.warrantyAllowedOutcomes : {};
  });

  const validationCheck = (lineItem) => {
    // Loading items should show as valid until we can actually figure out if they're valid or not
    if (lineItem.loading) {
      return true;
    }

    // This item failed to load
    if (lineItem.failed) {
      return false;
    }

    // The item has already been returned
    if (lineItem.allowed?.returned) {
      return false;
    }

    if (lineItem.status?.some((status) => status.status === orderLineItemStatuses.HasActiveShippingProtectionClaim
      || status.status === orderLineItemStatuses.HasPendingShippingProtectionClaim)
    ) {
      return false;
    }

    const warrantyExchangeAllowed =
      (warrantyAllowedOutcomes.value?.shopNow || warrantyAllowedOutcomes.value?.replace) &&
      lineItem.qualifiedWarranties.length > 0 && !lineItem.status?.some((status) => status.status === orderLineItemStatuses.Reject);

    // All exchange/return flows are blocked
    const exchangeBlocked =
      !lineItem.allowed?.exchange && !warrantyExchangeAllowed;
    const returnBlocked = !lineItem.allowed?.return || (!lineItem.allowed?.gift && !lineItem.allowed?.refund);

    if (exchangeBlocked && returnBlocked) {
      return false;
    }

    // The item is past the warranty window
    return !(store.getters.settings.warrantiesEnabled && !order.value?.warranty_window_active && !order.value?.return_window_active && !order.value?.allowlisted);
  };

  const isReturnCoverageProduct = (lineItem) => {
    return lineItem?.is_return_coverage === true;
  };

  const loadVariants = async (productId, lineItemId) => {
    const currentLineItem = order.value.line_items[lineItemId];

    if (currentLineItem && !currentLineItem.product_exists) {
      await store.dispatch('updateLineItem', {
        id: lineItemId,
        allowed: {
          ...currentLineItem.allowed,
          exchange: false,
        },
        variants: [],
        options: [],
      });
      return false;
    }

    try {
      const { variants } = await store.dispatch('products/get', {
        id: productId,
      });

      return await store.dispatch('updateLineItem', {
        id: lineItemId,
        variants,
      });
    } catch (error) {
      console.error(error);
      console.error(`Variants for line item "${lineItemId}" failed to load`);
      await store.dispatch('updateLineItem', {
        id: lineItemId,
        allowed: {
          ...currentLineItem?.allowed,
          exchange: false,
        },
        variants: [],
        options: [],
        product_exists: false,
      });

      return Promise.reject(error);
    }
  };

  const loadLineItem = async (lineItemId, productTitle) => {
    try {
      const lineItem = await Order.getLineItem(lineItemId);

      lineItem.title = replacePlaceholderUsingObject(
        productTitle,
        lineItem
      );

      store.commit('updateOrder', {
        ...order.value,
        line_items: {
          ...(order.value.line_items ?? {}),
          [lineItem.id]: lineItem,
        },
      });
      // We use this to reset changes made to line items in workflows
      store.commit('setOriginalLineItems', {
        ...(order.value?.line_items ?? {}),
        [lineItem.id]: cloneDeep(lineItem),
      });

      const startEventId = order.value.return_policy?.return_window_event?.start_return_window_event_type_id;
      const returnWindow = order.value.return_policy?.return_window;

      track(customerPortalEvents.RETURN_ELIGIBILITY_DETAILS, {
        ...store.getters['analytics/getData'],
        startReturnWindowEventTypeId: startEventId,
        returnWindow: returnWindow,
        deliveredAt: lineItem.delivered_at,
        tracking: lineItem.tracking,
        allowed: lineItem.allowed,
        excluded: lineItem.excluded,
      });

      await loadVariants(lineItem.product_id, lineItem.id);
    } catch (error) {
      store.commit('updateOrder', {
        ...order.value,
        line_items: {
          ...(order.value.line_items ?? {}),
          [lineItemId]: {
            failed: true,
          },
        },
      });

      console.error(error);
      console.error(`Product ${lineItemId} failed to load`);
    }
  };

  return {
    availableDestination,
    bundleItems,
    eligibleItems,
    ineligibleLineItems,
    resellLineItems,
    lineItems,
    nonBundleLineItems,
    order,
    selectedItems,
    warrantyAllowedOutcomes,

    isReturnCoverageProduct,
    loadLineItem,
    loadVariants,
    validationCheck,
  };
}
