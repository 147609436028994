<template>
  <base-tooltip
    class="product-tooltip"
    :content="tooltip"
    placement="center"
  >
    <component
      :is="product.returnType ? 'div' : 'button'"
      :disabled="disabled && !product.returnType"
      class="product-card exchange-item card"
      :class="{
        loading: loadingItem == product.id,
        selected: product.returnType != undefined,
        disabled: disabled && !product.returnType,
        'bundle-card': product.bundle_oli_id,
      }"
      v-on="product.returnType ? {} : { click: selectProduct }"
      @click="product.returnType ? null : selectProduct"
    >
      <div
        class="exchange-item__contents"
      >
        <!-- conditional overlay -->
        <div class="exchange-item__overlay">
          <div class="exchange-item__overlay__loader"></div>
        </div>

        <!-- rule tag -->
        <div
          v-if="product.rules?.applied.length > 0"
          class="exchange-item__rule"
        >
          <div class="exchange-item__rule__item">
            <p>{{ product.rules.applied[product.rules.applied.length - 1].name }}</p>
          </div>
        </div>
        <div
          v-if="isBundle"
          class="exchange-item__bundle-wrapper"
        >
          <product-bundle-image
            :product="product"
          />
        </div>
        <product-card-image
          v-else
          :product="product"
        />

        <div class="exchange-item__info">
          <base-text
            v-if="destination"
            class="destination"
            tag="span"
            type="body-2"
          >
            {{ destination }}
          </base-text>

          <base-text
            class="exchange-item__title"
            tag="span"
            type="display-small"
          >
            {{ product.title }}
          </base-text>

          <p
            v-if="product.option1 != 'Default Title'"
            class="exchange-item__copy"
          >
            {{ product.variant_title }}
          </p>

          <p
            v-if="showReturnText"
            class="exchange-item__new"
          >
            <render-content>
              {{ $content.pageOrder.selectedItem }}
            </render-content>
          </p>
          <p
            v-else-if="product.advanced"
            class="exchange-item__new"
          >
            <render-content>
              {{ $content.moduleLineItem.newProduct }}
            </render-content>
          </p>
          <p
            v-else-if="showExchangeText"
            class="exchange-item__new"
          >
            <render-content>
              <template v-if="product?.new_variant?.variant_title !== 'Default Title'">
                {{ $content.moduleLineItem.exchangeFor }}
                {{ product.new_variant.variant_title }}
              </template>
              <template v-else>
                {{ $content.moduleLineItem.exchangeItem }}
              </template>
            </render-content>
          </p>
          <p
            v-else-if="product.manualReview === false"
            class="exchange-item__new"
          >
            <render-content>
              {{ $content.moduleLineItem.manualReview }}
            </render-content>
          </p>
          <p
            v-else-if="attachedReason"
            class="exchange-item__new"
          >
            <render-content>
              {{ attachedReason }}
            </render-content>
          </p>
          <product-price
            v-else
            :product="product"
          />
          <store-credit-only-badge
            v-if="splitRefundsEnabled"
            :product="product"
          />
        </div>

        <base-button
          v-if="product.returnType"
          type="link"
          class="exchange-item__remove"
          @click="discardTransaction"
        >
          <div class="exchange-item__remove-content">
            <render-content>{{ $content.global.remove }}</render-content>
          </div>
        </base-button>

        <div
          v-if="!product.returnType"
          class="exchange-item__icon-arrow"
        >
          <div class="exchange-item__icon-arrow__wrapper">
            <base-icon name="arrow-right" />
          </div>
        </div>
      </div>
      <div
        v-if="shippingProtectionStatusBadge"
        class="shipping-protection-badge"
      >
        <div class="shipping-protection-badge__content">
          {{ shippingProtectionStatusBadge }}
        </div>
      </div>
      <div class="product-additional-wrapper">
        <slot
          name="product-additional"
          class="product-additional"
        />
      </div>
    </component>
  </base-tooltip>
</template>

<script>
import {
  BaseIcon,
  BaseText,
  VisuallyHidden,
  BaseTooltip,
  BaseButton,
} from '@loophq/design-system';
import ProductCardImage from '@/components/product/ProductCardImage';
import ProductBundleImage from '@/components/product/ProductBundleImage';
import ProductPrice from '@/components/product/ProductPrice';
import StoreCreditOnlyBadge from '@/components/badges/StoreCreditOnlyBadge';
import { orderLineItemStatuses } from '@/js/constants/orderLineItemStatuses';

export default {
  components: {
    BaseButton,
    BaseIcon,
    BaseText,
    VisuallyHidden,
    BaseTooltip,
    ProductCardImage,
    ProductBundleImage,
    ProductPrice,
    StoreCreditOnlyBadge
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    loadingItem: {
      type: [Number, String],
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    isBundle: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['selectProduct', 'discardTransaction'],
  computed: {
    destination() {
      const hasSingleDestination = this.product.destinations?.length === 1;

      if (hasSingleDestination) {
        const destination = this.product.destinations[0];
        return destination.display_name_in_portal ? destination.name : null;
      }

      return null;
    },
    tooltip() {
      const allowed = this.product.allowed;
      const isReturned = this.product.returnType && allowed.returned;
      const canBeReturned =
        allowed.return && (allowed.gift || allowed.refund) && !allowed.reason;

      // This tooltip should only show if the line item is disabled but actually eligible
      // Line items that are already returned should not show it
      // Line items that can't be returned due to a shipping protection claim should also not show it
      if (
        this.disabled &&
        !isReturned &&
        canBeReturned &&
        !this.hasPendingShippingProtectionClaim &&
        !this.hasActiveShippingProtectionClaim
      ) {
        return this.$content.pageOrder.multipleShipmentsExplanation;
      }

      return null;
    },
    attachedReason() {
      if (this.product?.allowed?.reason) {
        const defs = {
          ...this.$content.moduleLineItem,
          ...this.$content.moduleLookupErrors,
        };

        return defs[this.product.allowed.reason] ?? null;
      }
      return null;
    },
    itemIsExchangeInCart() {
      return (
        this.product.returnType === 'credit' &&
        this.$store.state.order?.cart?.find((item) => item?.lineItem === this.product.id)
      );
    },
    showReturnText() {
      return this.product.returnType === 'credit' && !this.itemIsExchangeInCart;
    },
    showExchangeText() {
      return this.product.returnType === 'exchange' || this.itemIsExchangeInCart;
    },
    splitRefundsEnabled() {
      return this.$store.getters.settings.enableSplitRefunds;
    },
    statuses() {
      return this.product.status?.map(item => item.status);
    },
    shippingProtectionStatusBadge() {
      if (this.hasActiveShippingProtectionClaim) {
        return this.$content.moduleLineItem.hasActiveClaim;
      }
      if (this.hasPendingShippingProtectionClaim) {
        return this.$content.moduleLineItem.hasPendingClaim;
      }
      return null;
    },
    hasPendingShippingProtectionClaim() {
      return this.statuses && this.statuses.includes(orderLineItemStatuses.HasPendingShippingProtectionClaim);
    },
    hasActiveShippingProtectionClaim() {
      return this.statuses && this.statuses.includes(orderLineItemStatuses.HasActiveShippingProtectionClaim);
    },
  },
  methods: {
    selectProduct() {
      this.$emit('selectProduct', this.product);
    },
    discardTransaction() {
      this.$emit('discardTransaction', this.product);
    },
  },
};
</script>

<style lang="scss">
.product-additional {
  padding-top: var(--spacing-200);
}
</style>

<style lang="scss" scoped>
.product-card {
  text-align: left;
  border-radius: var(--corners);

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--corners);
    opacity: 0;
    box-shadow: inset 0 2px 6px transparent;
    transition:
      opacity var(--transition-300),
      background-color var(--transition-300),
      box-shadow var(--transition-300);
  }

  &.focus-visible {
    outline: none;

    &::before {
      opacity: 0.5;
      box-shadow: inset 0 0 0 4px var(--primary-color);
    }

    // Firefox buttons have an inner focus ring for some reason
    &::-moz-focus-inner {
      border: none;
    }
  }

  :deep(.base-tooltip__content) {
    max-width: 400px !important;
  }
}

.product-additional-wrapper {
  pointer-events: auto; /* Ensure clicks are captured */
  width: 100%;
}

.bundle-card {
  margin-bottom: var(--spacing-300);
}

.destination {
  margin-bottom: var(--spacing-100);
}

.exchange-item {
  display: flex;
  flex-direction: column;

  :deep(.base-button__text) {
    background-image: none !important;
    font-size: 13px !important;
  }

  &__contents {
    display: flex;
    flex-grow: 1;
    width: 100%;
  }

  .selected {
    pointer-events: none;
  }

  &__remove-content {
    white-space: nowrap;
  }

  &__icon-arrow {
    color: $black;
    align-content: center;
  }
}

.shipping-protection-badge {
  margin-top: 10px;

  &__content {
    background-color: var(--grey-200);
    padding: 0.25rem 0.5rem;
    border-radius: var(--corners);
    display: inline;
  }
}
</style>
