export const carriers = {
  USPS: 'USPS',
  USPSRETURNS: 'USPSReturns',
  FEDEX: 'FedEx',
  INPOST: 'inpost',
  ASDA: 'asda',
  EVRI: 'evri',
  DHLSEKO: 'DHL-SEKO',
  CANADA_POST: 'CanadaPost',
  ROYAL_MAIL: 'RoyalMail',
  NEWGISTICS: 'newgistics',
};
