<template>
  <base-button
    v-bind="$attrs"
    class="progress-button"
    :class="[{ outline: active }]"
    type="secondary"
  >
    <span
      v-if="$slots.heading"
      class="progress-heading"
    >
      <slot name="heading"></slot>
    </span>
    <span
      v-if="$slots.default"
      class="progress-main"
    >
      <span
        class="progress-text"
        :class="{ 'center-text': centerText }"
      >
        <render-content :data="customizationData">
          <slot></slot>
        </render-content>
      </span>
      <template v-if="$slots.badge">
        <slot name="badge"></slot>
      </template>
      <base-icon
        v-if="!hideArrow"
        class="progress-arrow"
        name="chevron-right"
      />
    </span>
    <template v-if="$slots.content">
      <slot name="content"></slot>
    </template>
  </base-button>
</template>

<script>
import {
  BaseButton,
  BaseIcon,
} from '@loophq/design-system';

export default {
  components: {
    BaseButton,
    BaseIcon,
  },
  props: {
    hideArrow: {
      type: Boolean,
      default: false,
    },
    centerText: {
      type: Boolean,
      default: false,
    },
    customizationData: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
  }
};
</script>

<style lang="scss" scoped>
.progress-button {
  width: 100%;
}

.progress-main,
.progress-heading {
  width: 100%;
  display: flex;
}

.progress-heading {
  @include font-body;

  font-weight: 600;
  color: var(--color-slate-800);
  text-align: left;
  flex-grow: 1;
  margin-bottom: var(--spacing-100);
}

.progress-text {
  @include font-body;

  color: var(--color-slate-800);
  text-align: left;
  flex-grow: 1;

  &.center-text {
    text-align: center;
  }
}

.progress-arrow {
  --icon-color: var(--grey-900);

  margin-right: -0.5rem;
  height: 1.3rem;
  width: 1.3rem;
}

.outline {
  border: 1px solid transparent !important;
  box-shadow: 0 0 0 2px var(--primary-color) !important;
}
</style>
